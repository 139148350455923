<template>
  <transition name="fade-in" mode="out-in">
    <AppSpinner v-if="isLoading" />
  </transition>
  <main class="page-stock layout--stock" v-if="data">
    <AppPageHeader
      :headerImg="headerImg"
      :headerAlt="headerAlt"
      :headerText="headerText"
      :title="title"
    />

    <FilterContainer
      :itemCount="data.length"
      :filters="filters"
      title="Filter Boats"
    />

    <section class="s-stock">
      <router-link
        :to="{ name: 'boat', params: { id } }"
        class="s-stock__item card card--stock"
        v-for="{
          id,
          thumbnail,
          condition,
          price,
          title,
          year,
          make,
          model
        } in chunkedBoats"
        :key="id"
      >
        <div class="s-stock__brand card__brand">
          <!-- <component :is="`SVGLogo${Specification.Make}`" /> -->
          <SVGLogoMalibu />
        </div>

        <figure class="s-stock__img card__thumb">
          <img :src="thumbnail" :alt="title" />
        </figure>

        <div class="s-stock__indicator card__indicator">{{ condition }}</div>

        <section class="s-stock__meta card__meta">
          <h3 class="s-stock__title card__title">
            <span class="s-stock__title--pre card__title--pre">{{ year }} {{ make }}</span>
            {{ model }}
          </h3>

          <p class="s-stock__price card__price">{{ price }}</p>
        </section>
      </router-link>

      <div class="s-stock__actions card__actions" v-if="canViewMoreBoats">
        <AppButton display="secondary" @click="handleShowMore">Show more boats</AppButton>
      </div>
    </section>
  </main>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useQuery } from '@composables/useQuery'

import AppPageHeader from '@/components/AppPageHeader.vue'
import FilterContainer from '@/components/FilterContainer.vue'
import SVGLogoMalibu from '@/assets/svgs/logo-malibu'

const headerImg = ref('https://mm-aws-s3-media.s3.ap-southeast-2.amazonaws.com/large_ROD_4403_da5b42bd4e.jpeg')
const headerAlt = 'Boat'
const headerText = '19 Boats Available'
const title = 'Latest Stock'

const filters = [
  {
    id:'e37a89b4-3657-425f-bb01-5e3059662591',
    name: 'Price',
    options: {
      min: 0,
      max: 1000000,
      step: 100,
      value: [0, 1000000]
    },
    type: 'range'
  },
  {
    id:'1018aae9-2e69-407c-ba47-0fcac468d30a',
    name: 'Length (Metres)',
    options: {
      min: 0,
      max: 12,
      step: 0.5,
      value: [0, 12]
    },
    type: 'range'
  },
  {
    id:'1bd4d5ee-217d-4310-940b-17423dc45f28',
    name: 'Make',
    options: [
      { malibu: 'Malibu' },
      { axis: 'Axis' }
    ],
    type: 'checkbox'
  },
  {
    id:'d5fc0662-4cae-49d3-a0f6-ea1cd4a6fd1a',
    name: 'Condition',
    options: [
      { new: 'New' },
      { demo: 'Demo' },
      { used: 'Used' }
    ],
    type: 'checkbox'
  },
  {
    id:'7689daff-7289-48f9-a393-d93e84508c9b',
    name: 'Year',
    options: [
      { 2022: '2022' },
      { 2021: '2021' },
      { 2020: '2020' },
      { 2019: '2019' },
      { 2018: '2018' },
      { 2017: '2017' }
    ],
    type: 'checkbox'
  }
]

const { isLoading, data } = useQuery('stock', 'https://api.melbournemalibu.com.au/get-boats')
const boatCount = ref(6)
const chunkedBoats = computed(() => {
  return data?.value.slice(0, boatCount.value)
})

const canViewMoreBoats = computed(() => boatCount.value <= data.value.length)

const handleShowMore = () => boatCount.value += 6
</script>
